import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We just published a new version of Trailguide. Now you see local services on
the map. From the point of interest you directly get to the information about
the service. See the website, contact info, and can get a route to its location.`}</p>
    <Image src="news/localservicesmap2.jpg" mdxType="Image" />
    <p>{`The local services are visible as points of interest. The icon shows the type
of service. Click on it to open the info bar with more information. This
example shows `}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=13,60.5722,9.1133&map=cyclemap"
      }}>{`Nesbyen`}</a>{`
with the guiding and shuttle service `}<a parentName="p" {...{
        "href": "https://www.trailheadnesbyen.no"
      }}>{`Trailhead Nesbyen`}</a>{`
and the biker friendly accomodation `}<a parentName="p" {...{
        "href": "http://www.hagaled.no"
      }}>{`Hagaled Gjestegård`}</a></p>
    <Image src="news/localservicesmaponly.jpg" mdxType="Image" />
    <p>{`The points of interest appear when you zoom into the map. This example shows
`}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=13,47.9952,7.8736&map=cyclemap"
      }}>{`Freiburg`}</a>{`
with the bike shop `}<a parentName="p" {...{
        "href": "href='https://www.t-2.de"
      }}>{`Sport Tout Terrain`}</a>{` and the biker
friendly campsite `}<a parentName="p" {...{
        "href": "href='https://www.freiburg-camping.de"
      }}>{`Camping Hirzberg`}</a>{`.`}</p>
    <Image src="news/localservicesdescription.jpg" mdxType="Image" />
    <p>{`You also see the local services in all trails nearby their location. Click on
the entry to open more information like a description, link to the website,
contact information or to get a route to their location.\\
\\
If you have a local service, see how to get it on Trailguide
`}<a parentName="p" {...{
        "href": "https://trailguide.no/news/adsforlocalservices"
      }}>{`here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      